.wrapperContainer {
	background-color: var(--chatnshop-mainBg);
}

.container {
	max-width: 480px;
	margin: 0 auto;
	min-height: 100vh;
	background-color: var(--chatnshop-mainBg);
	padding-top: 4.25rem;
}

.error {
	padding: 1rem;
	margin-top: 0;
}

@media (min-width: 481px) {
	.container {
		border-left: 2px solid var(--chatnshop-mainBg);
		border-right: 2px solid var(--chatnshop-mainBg);
	}
}
